/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import SpeechRecorder from "languify-speech-recorder";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';
import MicIcon from '@mui/icons-material/Mic';
import CircularProgressCountDown from 'components/CircularProgressCountDown';
import CustomButton from 'components/CustomButton';
import TroubleshootDialog from '../dialogs/TroubleshootDialog';
import Divider from '@mui/material/Divider';

import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from "contexts/SnackbarProvider";
import { useNavigateWithClient } from 'hooks';
import { Session, askPermission, formatDate, subscribeToAudioLevel } from 'utils';
import { getTemplateById } from 'services';
import { useLoading } from 'contexts';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  paper: {
    border: '2px solid #CCD4DE',
    borderRadius: '8px',
  },
  reset: {
    width: "50%", fontFamily: 'Montserrat', fontWeight: 500, fontSize: 15,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "none"
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 6)
  },
  autoClosingText: {
    ...theme.typography['body02-bold'],
    background: theme.palette.danger['clr-100'],
    borderRadius: theme.spacing(4),
    height: 'fit-content', padding: theme.spacing(2, 3),
    color: theme.palette.danger['clr-700'],
    display: 'flex', alignItems: 'center'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', padding: theme.spacing(4, 6),
    marginTop: '15px'
  },
  label: {
    ...theme.typography['body01-semiBold']
  },
  value: {
    ...theme.typography['body01-bold'],
    color: theme.palette.primary['clr-300'],
    width: '450px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  },
  instructions: {
    ...theme.typography['body01-semiBold'],
    marginBottom: theme.spacing(2)
  },
  micRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  envoirnmntBox: {
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.success['clr-100'],
    width: '335px',
    borderRadius: '4px'
  }
}));

const neverExpiryYear = 9999;

function StartAssessmentForAPI({ templateId }) {
  const theme = useTheme();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const loading = useLoading();
  const navigate = useNavigateWithClient();

  const [state, setState] = React.useState("INITIAL");
  const [audioLevel, setAudioLevel] = React.useState(0);
  const [matched, setMatched] = React.useState(false);
  const [openTroubleshootDialog, setOpenTroubleshootDialog] = React.useState(false);
  const [template, setTemplate] = React.useState(null);

  const unsubscribeRef = React.useRef();
  const speechRecorderRef = React.useRef();

  const { maxAllowedAttempts, templateName, templateType, testTime, expiresOn, } =
    React.useMemo(() => ({
      maxAllowedAttempts: template?.maxAllowedAttempts,
      templateType: template?.type,
      templateName: template?.name,
      testTime: template?.metadata?.testTime,
      expiresOn: template?.expiresOn,
      id: template?._id
    }), [template]);

  const instructions = {
    "assignment": [
      <>You <b>can only attempt this assessment once</b></>,
      <>You will have a certain amount of time to complete each question, so manage your time carefully.</>,
    ],
    "practice": [
      <>This is a <b>practice</b> assessment, you can attempt it <b>
        {
          (maxAllowedAttempts > 0) ?
            `${maxAllowedAttempts} times.` :
            'as many times you want.'}
      </b></>,
      <>You will have a certain amount of time to complete each question, so manage your time carefully.</>,
    ],
    "follow_up": [
      <>This is a <b>follow up</b> assessment, you can attempt it <b>
        {
          (maxAllowedAttempts > 0) ?
            `${maxAllowedAttempts} times.` :
            'as many times you want.'}
      </b></>,
      <>You will have a certain amount of time to complete each question, so manage your time carefully.</>,
    ]
  };

  // Initializing and Fetching template
  useEffect(() => {
    (async () => {
      try {
        loading.show();
        setMatched(false);

        const template = await getTemplateById(templateId);

        setTemplate(template);
        sessionStorage.setItem('selectedBatch', template.batch);
        openPermissionPrompt();

      } catch (error) {
        console.log(error);
      } finally {
        loading.hide();
      }
    })();
  }, [templateId]);

  // Initializing Speech Recognition
  React.useEffect(() => {
    if (!speechRecorderRef.current) {
      const isMobile = true;
      const serverUrl = process.env.REACT_APP_WEB_SOCKET_URL;
      const query = { token: Session.accessToken, application: 'none' };

      speechRecorderRef.current = new SpeechRecorder({
        isMobile, serverUrl, query, onInterimTranscript: (transcript) => {
          if (transcript.toLowerCase().search('lets go') !== -1) {
            console.info('DEBUG::', transcript);
            setMatched(true);
            reset();
          };
        }
      });

      startRecording();
    }
  }, []);

  const openPermissionPrompt = async () => {
    const isPermitted = await askPermission({ audio: true, video: true });

    if (isPermitted) {
      subscribe();
      setState("GRANTED");
    } else {
      setState("ASK_PERMISSION");
    }
  }

  const startRecording = React.useCallback(async () => {
    if (speechRecorderRef.current) {
      try {
        await speechRecorderRef.current.start();
        console.log('Recording started.');
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const subscribe = React.useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });

      unsubscribeRef.current = subscribeToAudioLevel(stream, (level) => setAudioLevel(level));
      console.log("subscribed.");
    } catch (error) {
      console.error(error);
    }
  }, []);

  const reset = React.useCallback(async () => {
    if (unsubscribeRef.current && typeof unsubscribeRef.current === 'function') {
      unsubscribeRef.current();
      unsubscribeRef.current = null;
      console.log('unsubscribed.');
    }
    if (speechRecorderRef.current) {
      speechRecorderRef.current.stop();
      speechRecorderRef.current.destructor();
      speechRecorderRef.current = null;
      console.log('Recording stopped.');
    }
  }, []);

  const proceedToTest = React.useCallback(() => {
    navigate(`/assessment?tid=${templateId}`);
  }, [templateId]);

  return (
    <>
      <Box className={classes.container} >
        <Box className={classes.paper}>
          {
            !matched ?
              <>
                <Box className={classes.header}>
                  <Typography id="customized-dialog-title" variant='h6-medium'>
                    Assessment
                  </Typography>
                </Box>

                <Divider />

                <Box className={classes.contentContainer}>
                  <Box display='flex' gap={1} alignItems='center' mb={1}>
                    <img
                      src='https://assets.languify.in/images/assessment-icon.svg'
                      alt='Assessment'
                    />
                    <Typography className={classes.label}>
                      Assessment
                    </Typography>
                    <Typography className={classes.value} color='primary'>
                      {templateName}
                    </Typography>
                  </Box>
                  <Box display='flex' gap={1} alignItems='center' mb={1}>
                    <img
                      src='https://assets.languify.in/images/time-icon.svg'
                      alt='Assessment'
                    />
                    <Typography className={classes.label}>
                      {
                        templateType === 'pathway' ?
                          'Test Time :' : 'Maximum Time Allowed :'
                      }
                    </Typography>
                    <Typography className={classes.value} color='primary'>
                      {testTime ?
                        (testTime > 60) ?
                          `${Math.ceil((testTime) / 60)} minutes` :
                          `${testTime} secs`
                        : "5-10 mins"
                      }
                    </Typography>
                  </Box>
                  <Box display='flex' gap={1} alignItems='center'>
                    <img
                      src='https://assets.languify.in/images/deadline-icon.svg'
                      alt='Assessment'
                    />
                    <Typography className={classes.label}>Deadline : </Typography>
                    <Typography className={classes.value} color='#C51407'>
                      {
                        expiresOn &&
                          dayjs(expiresOn).year() !== neverExpiryYear ?
                          formatDate(expiresOn, "DD MMMM, hA") :
                          "Never expires"
                      }
                    </Typography>
                  </Box>

                  <Typography variant='body01-bold' mb={1} mt={3}>
                    General Instructions
                  </Typography>
                  {instructions[template?.type]?.map((instruction, i) => (
                    <li key={i} className={classes.instructions}>
                      {instruction}
                    </li>
                  ))}

                </Box>

                <Divider />

                <Box className={classes.contentContainer}>

                  <Typography align='center' variant='body02-bold' my={2} color='success.clr-700'>
                    Remember to sit in a noise free environment
                  </Typography>

                  <Typography align='center' mb={2} mt={1} variant='h6-regular'>
                    Speak &nbsp;
                    <b
                      style={{
                        color: matched ? theme.palette.neutral['clr-700']
                          : theme.palette.primary.main
                      }}
                    >
                      “Let’s Go”</b>
                    &nbsp; to start your assessment
                  </Typography>

                  <Box className={classes.micRow}>
                    <MicIcon style={{ fontSize: '18px' }} />
                    <Box sx={{ width: '120px' }}>
                      <LinearProgress
                        variant="determinate" value={audioLevel}
                        style={{ borderRadius: '10px' }}
                      />
                    </Box>
                  </Box>

                  {
                    (state === 'ASK_PERMISSION') &&
                    <Box
                      display='flex' alignItems='center' gap={2}
                      width={'100%'} justifyContent='center'
                    >
                      <Typography fontSize={15} color='error' fontWeight={500}>
                        It seems you have blocked mic or camera permissions!
                      </Typography>

                      <CustomButton
                        variant='contained'
                        onClick={openPermissionPrompt}
                      >
                        Give Permission
                      </CustomButton>
                    </Box>
                  }

                  <Typography variant='body02-semiBold' textAlign='center' mb={2}>
                    Facing any issue with mic? &nbsp;
                    <a
                      href='#' style={{ color: theme.palette.primary.main, textDecoration: 'underline' }}
                      onClick={() => setOpenTroubleshootDialog(true)}
                    >
                      Troubleshoot here
                    </a>
                  </Typography>
                </Box>
              </>

              : <CircularProgressCountDown proceedToTest={proceedToTest} />
          }
        </Box>
      </Box>
      <TroubleshootDialog
        open={openTroubleshootDialog}
        onClose={() => setOpenTroubleshootDialog(false)}
      />
    </>
  );
}

export default StartAssessmentForAPI;